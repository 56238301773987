<template>
  <v-container fluid class="fill-height" style="height: 60vh">
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <v-icon size="150">mdi-emoticon-sad-outline</v-icon>
        <h2 class="display-3 mb-10 mt-0">{{ __('Lead não encontrada', 'leads') }}</h2>
        <v-btn color="primary" text to="/leads">
          <v-icon left>mdi-keyboard-return</v-icon>{{ __('Retornar para leads', 'leads') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/mixins/i18n'; 

export default {
  mixins: [i18n],
}
</script>